import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../img/nsoplay.png";
import Logonsovn from "../../img/nsovn.png";
import LogoGif from "../../img/nsoplay.gif";
import CustomModal from '../../Commont/CustomModal';
import { CustomAxios } from '../../Service/CustomAxios';
import { Config, PusherConfig } from '../../Config';
import Notiflix from 'notiflix';
import Pusher from 'pusher-js';
import { CustomAuth } from '../../Service/CustomAuth';

const Header = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [errormsg, seterrormsg] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [forgetPassword, setforgetPassword] = useState(false);
    const [showActive, setShowActive] = useState(false);
    const [changeEmail, setchangeEmail] = useState(false);
    const access_token = localStorage.getItem("access_token");

    useEffect(() => {
        if (errormsg) {
            setTimeout(() => {
                seterrormsg(null);
            }, 2000);
        }
    }, [errormsg])

    const verify = async () => {
        const user = await CustomAuth();
        if (user !== null) {
            setData(user);

            if (user?.data.email === "" || user?.data.email === null || user?.data.email === undefined) {
                console.log(user?.data.username);
                setchangeEmail(true);
            }
        }
    }

    const navClick = (params) => {
        if (!access_token) {
            if (params === '/home') {
                window.location.href = params;
                return;
            }
            setShowLogin(true);
        } else {
            window.location.href = params;
        }
    }

    const pingPusher = () => {
        // console.log("PING");
        if (!access_token) {
            return;
        }
        const pusher = new Pusher(PusherConfig.key, {
            cluster: PusherConfig.cluster,
        });
        const channel = pusher.subscribe(PusherConfig.channelName);
        channel.bind(`event-${data?.data.id}`, (data) => {
            if (data && data.status === true) {
                Notiflix.Report.success(
                    "Thông báo",
                    data.contents,
                    "Đóng",
                    function cb() {
                        window.location.reload();
                    },
                );
            } else {
                Notiflix.Report.failure("Thông báo", data.contents, "Đóng");
            }
        });
        return () => {
            channel.unbind();
            pusher.unsubscribe(PusherConfig.channelName);
        };
    }

    useEffect(() => {
        if (access_token) {
            verify();
        }
    }, [access_token])

    if (access_token) {
        pingPusher();
    }

    const handleShowLogin = () => {
        // seterrormsg(null);
        setShowRegister(false);
        setforgetPassword(false);
    }
    const handleModalLogin = () => {
        setShowLogin(!showLogin);
    };
    const handleShowRegister = () => {
        // seterrormsg(null);
        setShowLogin(false);
        setforgetPassword(false);
    }
    const handleModalRegister = () => {
        setShowRegister(!showRegister);
    };
    const handleShowForget = () => {
        // seterrormsg(null);
        setShowRegister(false);
        setShowLogin(false);
    }
    const handleShowchangeEmail = () => {
        // seterrormsg(null);
        setShowActive(false);
    }
    const handleModalForget = () => {
        setforgetPassword(!forgetPassword);
    };
    const handleModalchangeEmail = () => {
        setchangeEmail(!changeEmail);
    };
    const handleModalActive = () => {
        setShowActive(!showActive);
    };
    const LogoutUser = () => {
        localStorage.clear();
        window.location.reload();
    }
    const submitLogin = async (e) => {
        e.preventDefault();
        const { username, password } = document.forms[0]
        if (username.value && password.value) {
            try {
                setLoading(true);
                const response = await CustomAxios(Config.login,
                    {
                        username: username.value,
                        password: password.value,
                    },
                    {
                        Authorization: 'Bearer ',
                        "Content-Type": 'application/json'
                    }, 'POST');

                if (response.status === 200) {
                    if (response.data.status === false) {
                        seterrormsg(response.data.message);
                    }

                    if (response.data.status === true) {
                        localStorage.setItem("access_token", response.data.access_token);
                        window.location.reload();
                    }
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
    }
    const submitRegister = async (e) => {
        e.preventDefault();
        const { username, password, email, confirm_password } = document.forms[0]
        if (username.value && password.value && email.value && confirm_password.value) {
            try {
                setLoading(true);
                const response = await CustomAxios(Config.register,
                    {
                        username: username.value,
                        password: password.value,
                        email: email.value,
                        password_confirmation: confirm_password.value,
                        confirm_password: confirm_password.value,
                    },
                    {
                        Authorization: 'Bearer ',
                        "Content-Type": 'application/json'
                    }, 'POST');

                if (response.status === 200) {
                    if (response.data.status === false) {
                        seterrormsg(response.data.message);
                    }

                    if (response.data.status === true) {
                        localStorage.setItem("access_token", response.data.access_token);
                        window.location.href = "/home";
                    }
                }
            } catch (error) {
                Notiflix.Report.failure(
                    "Thông báo",
                    "Lỗi kết nối internet",
                    "Đồng ý"
                )
            } finally {
                setLoading(false);
            }
        }
    }
    const submitForgot = async (e) => {
        e.preventDefault();
        const { username, email } = document.forms[0]
        if (username.value && email.value) {
            try {
                setLoading(true);
                const response = await CustomAxios(Config.sendemail,
                    {
                        username: username.value,
                        email: email.value,
                    },
                    {
                        Authorization: 'Bearer ',
                        "Content-Type": 'application/json'
                    }, 'POST');

                if (response.status === 200) {
                    if (response.data.status === false) {
                        seterrormsg(response.data.message);
                    }

                    if (response.data.status === true) {
                        Notiflix.Report.success(
                            "Thông báo",
                            response.data.message,
                            "Đồng ý"
                        )
                    }
                }
            } catch (error) {
                Notiflix.Report.failure(
                    "Thông báo",
                    "Lỗi kết nối internet",
                    "Đồng ý"
                )
            } finally {
                setLoading(false);
            }
        }
    }

    const submitChangeEmail = async (e) => {
        e.preventDefault();
        const { email } = document.forms[0]
        if (email.value) {
            try {
                setLoading(true);
                const response = await CustomAxios(Config.changeemail,
                    {
                        email: email.value,
                    },
                    {
                        Authorization: 'Bearer ' + access_token,
                        "Content-Type": 'application/json'
                    }, 'POST');

                if (response.status === 200) {
                    if (response.data.status === false) {
                        seterrormsg(response.data.message);
                    }

                    if (response.data.status === true) {
                        Notiflix.Report.success(
                            "Thông báo",
                            response.data.message,
                            "Đồng ý",
                            function cb() {
                                window.location.reload();
                            },
                        )
                    }
                }
            } catch (error) {
                Notiflix.Report.failure(
                    "Thông báo",
                    "Lỗi kết nối internet",
                    "Đồng ý"
                )
            } finally {
                setLoading(false);
            }
        }
    }

    const submitActive = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await CustomAxios(Config.active,
                {
                },
                {
                    Authorization: 'Bearer ' + access_token,
                    "Content-Type": 'application/json'
                }, 'POST');

            if (response.status === 200) {
                if (response.data.status === true) {
                    Notiflix.Report.success(
                        "Thông báo",
                        response.data.message,
                        "Đồng ý",
                        function cb() {
                            window.location.reload();
                        },
                    )
                } else {
                    Notiflix.Report.failure(
                        "Thông báo",
                        response.data.message,
                        "Đồng ý"
                    )
                }
            }
        } catch (error) {
            Notiflix.Report.failure(
                "Thông báo",
                "Lỗi kết nối internet",
                "Đồng ý"
            )
        } finally {
            setShowActive(false);
            setLoading(false);
        }
    }

    if (loading) {
        Notiflix.Loading.circle();
        return;
    }

    if (!loading) {
        Notiflix.Loading.remove();
    }

    const formatInt = (e) => {
        const formattedAmount = new Intl.NumberFormat();
        return formattedAmount.format(e);
    };

    const DivActive = () => {
        if (data?.data.activated === 0 || data?.data.activated === "0") {
            return (
                <div className="mt-2">
                    <small className="text-danger fw-semibold mt-3">Tài khoản của bạn chưa được kích hoạt, click vào phía dưới để kích hoạt.</small>
                    <div className="mt-2">
                        <span
                            onClick={() => setShowActive(true)}
                            className="mb-3 px-2 py-1 fw-semibold text-secondary bg-danger bg-opacity-25 border border-danger border-opacity-75 rounded-2 link-success cursor-pointer">Kích
                            hoạt tài khoản
                        </span>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    const CheckUser = () => {
        if (data?.status === true) {
            return (
                <>
                    <div>
                        <Link to='/user/profile' className="mb-3 px-2 py-1 fw-semibold text-secondary bg-warning bg-opacity-25 border border-warning border-opacity-75 rounded-2 link-success cursor-pointer">
                            {data?.data.username} - {formatInt(data?.data.balance)} PCoin
                        </Link>
                        <span
                            onClick={LogoutUser}
                            className="ms-1 mb-3 px-2 py-1 fw-semibold text-secondary bg-warning bg-opacity-25 border border-warning border-opacity-75 rounded-2 link-success cursor-pointer">Đăng
                            xuất
                        </span>
                    </div>
                    <DivActive />
                </>
            )
        } else {
            return (
                <div className="mt-3">
                    <span onClick={() => setShowLogin(true)} className="mb-3 px-2 py-1 fw-semibold text-secondary bg-warning bg-opacity-25 border border-warning border-opacity-75 rounded-2 link-success cursor-pointer">Đăng nhập</span>
                    {"  "}
                    <span onClick={() => setShowRegister(true)} className="mb-3 px-2 py-1 fw-semibold text-secondary bg-warning bg-opacity-25 border border-warning border-opacity-75 rounded-2 link-success cursor-pointer">Đăng ký</span>
                </div>
            )
        }
    }

    return (
        <div>
            <div className="text-center card">
                <div className="card-body">
                    <div className=""><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '350px' }} /></Link></div>
                    <div className="mt-3">
                        <CheckUser />
                        <div className="mt-3"><Link to='/download' className="mb-3 px-2 py-1 fw-semibold text-danger bg-danger bg-opacity-25 border border-danger border-opacity-50 rounded-2 cursor-pointer">TẢI GAME</Link></div>
                    </div>
                </div>
            </div>

            <div className="mb-2">
                <div className="row text-center justify-content-center row-cols-2 row-cols-lg-6 g-2 g-lg-2 mt-1">
                    <div className="col">
                        <div className="px-2"><Link onClick={() => navClick('/home')} className="btn btn-menu btn-danger w-100 fw-semibold">Trang chủ</Link></div>
                    </div>
                    <div className="col">
                        <div className="px-2"><Link onClick={() => navClick('/recharge/bank')} className="btn btn-menu btn-danger w-100 fw-semibold">Nạp PCoin</Link></div>
                    </div>
                    <div className="col">
                        <div className="px-2"><Link onClick={() => navClick('/exchange')} className="btn btn-menu btn-danger w-100 fw-semibold">Đổi lượng</Link></div>
                    </div>
                    <div className="col">
                        <div className="px-2"><Link to='/community' className="btn btn-menu btn-danger w-100 fw-semibold">Box Zalo</Link></div>
                    </div>
                    <div className="col">
                        <div className="px-2"><Link to='https://sv2.nsovn.fun/home' className="btn btn-menu btn-danger w-100 fw-semibold">Truy cập SV 2</Link></div>
                    </div>

                </div>
            </div>

            <CustomModal isShow={showLogin} handleHide={handleModalLogin} handleShow={() => { handleShowLogin() }}>
                <div className="text-center"><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '150px' }} /></Link></div>
                <form action="#" className="py-3 mx-3" onSubmit={submitLogin}>
                    {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                    <div className="mb-2">
                        <div className="input-group"><input required name="username" type="text" autoComplete="off"
                            placeholder="Tên đăng nhập" className="form-control form-control-solid" /></div>
                    </div>
                    <div className="mb-2">
                        <div className="input-group"><input required name="password" type="password" autoComplete="off"
                            placeholder="Mật khẩu" className="form-control form-control-solid" /></div>
                    </div>
                    <div className="text-center mt-3"><button type="submit" className="me-3 btn btn-primary">Đăng
                        nhập</button><button type="button" className="btn btn-danger" onClick={handleModalLogin}>Hủy bỏ</button>
                        <div className="pt-3">Bạn chưa có tài khoản? <span className="link-primary cursor-pointer" onClick={handleModalRegister}>Đăng ký
                            ngay</span></div>
                        <div><span className="link-primary cursor-pointer" onClick={handleModalForget}>Quên mật khẩu</span></div>
                    </div>
                </form>
            </CustomModal>

            <CustomModal isShow={showRegister} handleHide={handleModalRegister} handleShow={() => { handleShowRegister() }}>
                <div className="text-center"><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '150px' }} /></Link></div>
                <form action="#" className="py-3 mx-3" onSubmit={submitRegister}>
                    {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                    <div className="mb-2"><label className="fw-semibold">Tên đăng nhập</label>
                        <div className="input-group"><input required name="username" type="text" autoComplete="off"
                            placeholder="Nhập tên đăng nhập" className="form-control form-control-solid" /></div>
                    </div>
                    <div className="mb-2"><label className="fw-semibold">Email</label>
                        <div className="input-group"><input required name="email" type="text" autoComplete="off"
                            placeholder="Nhập email" className="form-control form-control-solid" /></div>
                    </div>
                    <div className="mb-2"><label className="fw-semibold">Mật khẩu</label>
                        <div className="input-group"><input required name="password" type="password" autoComplete="off"
                            placeholder="Nhập mật khẩu" className="form-control form-control-solid" /></div>
                    </div>
                    <div className="mb-2"><label className="fw-semibold">Nhập lại mật khẩu</label>
                        <div className="input-group"><input required name="confirm_password" type="password" autoComplete="off"
                            placeholder="Nhập nhập lại mật khẩu" className="form-control form-control-solid" />
                        </div>
                    </div>
                    <div className="text-center mt-3"><button type="submit" className="me-3 btn btn-primary">Đăng
                        ký</button><button type="button" className="btn btn-danger" onClick={handleModalRegister}>Hủy bỏ</button>
                        <div className="pt-3">Bạn đã có tài khoản? <span className="link-primary cursor-pointer" onClick={handleModalLogin}>Đăng nhập
                            ngay</span></div>
                        <div><span className="link-primary cursor-pointer" onClick={handleModalForget}>Quên mật khẩu</span></div>
                    </div>
                </form>
            </CustomModal>

            <CustomModal isShow={changeEmail} handleHide={handleModalchangeEmail} handleShow={() => { handleShowchangeEmail() }}>
                <div className="text-center"><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '150px' }} /></Link></div>
                <form action="#" className="py-3 mx-3" onSubmit={submitChangeEmail}>
                    {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                    <div><span style={{ color: "red" }}>Bạn chưa cập nhật email, để đảm bảo an toàn cho tài khoản, hãy cập nhật email mới!</span></div>
                    <div className="mb-2"><label className="fw-semibold">Nhập email mới</label>
                        <div className="input-group"><input required name="email" type="text" autoComplete="off"
                            placeholder="Nhập email mới" className="form-control form-control-solid" /></div>
                    </div>
                    <div style={{ marginTop: "-10px" }}></div>
                    <div className="text-center mt-3"><button type="submit" className="me-3 btn btn-primary">Xác
                        nhận</button><button type="button" className="btn btn-danger" onClick={handleModalchangeEmail}>Hủy bỏ</button>
                    </div>
                </form>
            </CustomModal>

            <CustomModal isShow={forgetPassword} handleHide={handleModalForget} handleShow={() => { handleShowForget() }}>
                <div className="text-center"><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '150px' }} /></Link></div>
                <form action="#" className="py-3 mx-3" onSubmit={submitForgot}>
                    {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                    <div className="mb-2"><label className="fw-semibold">Tên đăng nhập</label>
                        <div className="input-group"><input required name="username" type="text" autoComplete="off"
                            placeholder="Nhập tên đăng nhập" className="form-control form-control-solid" /></div>
                    </div>
                    <div className="mb-2"><label className="fw-semibold">Email đăng ký</label>
                        <div className="input-group"><input required name="email" type="text" autoComplete="off"
                            placeholder="Nhập email đăng ký" className="form-control form-control-solid" /></div>
                    </div>
                    {/* <div className="mb-2"><label className="fw-semibold">Mã OTP</label>
                        <div className="input-group"><input required name="code" type="text" autoComplete="off"
                            placeholder="Nhập mã OTP đã nhận" className="form-control form-control-solid" pattern="\d*" maxLength="6" /><button className="btn btn-danger" type="button">Gửi mã OTP</button></div>
                    </div> */}
                    <div style={{ marginTop: "-10px" }}></div>
                    <div className="text-center mt-3"><button type="submit" className="me-3 btn btn-primary">Xác
                        nhận</button><button type="button" className="btn btn-danger" onClick={handleModalForget}>Hủy bỏ</button>
                        <div className="pt-3">Bạn đã có tài khoản? <span className="link-primary cursor-pointer" onClick={handleModalLogin}>Đăng nhập
                            ngay</span></div>
                    </div>
                </form>
            </CustomModal>

            <CustomModal isShow={showActive} handleHide={handleModalActive} handleShow={() => { }}>
                <form action='#' method='post' onSubmit={submitActive}>
                    <div className="text-center"><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '150px' }} /></Link></div>
                    <div className="text-center fw-semibold">
                        <div className="fs-6 mb-2">Xác nhận kích hoạt tài khoản</div><span>Sau khi kích hoạt, bạn sẽ mở khóa các tính năng giao
                            dịch</span>
                        <div className="text-success fw-bold">Phí kích hoạt: {data?.data?.cointactive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} PCoin</div>
                        <div className="mt-2">
                            <button type="submit" className="btn-rounded btn btn-primary btn-sm">Kích hoạt ngay</button>
                            <button type="button" className="btn-rounded btn btn-danger btn-sm" onClick={handleModalActive}>Hủy bỏ</button>
                        </div>
                    </div>
                </form>
            </CustomModal>
        </div>
    );
};

export default Header;