import Notiflix from "notiflix";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../img/nsoplay.png";
import Logonsovn from "../../../img/nsovn.png";
import CustomModal from "../../../Commont/CustomModal";
import { Config } from "../../../Config";
import { CustomAxios } from "../../../Service/CustomAxios";
import LoadingImg from "../../../img/loading.gif";
import Small426 from "../../../img/Small426.png";

const Exchange = () => {
    const title = 'Đổi lượng';
    useEffect(() => {
        document.title = title;
    }, [title]);
    const [selectedId, setSelectedId] = useState(0);
    const [isdata, setdata] = useState([]);
    const [isdataChar, setdataChar] = useState([]);
    const [showDialog, setshowDialog] = useState(false);
    const access_token = localStorage.getItem("access_token");

    useEffect(() => {
        if (isdata) {
            setshowDialog(true);
        }
    }, []);

    const getPackage = async () => {
        try {
            const response = await CustomAxios(Config.exchange, {
            }, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                setdata(response.data.amounts);
                setdataChar(response.data.characters);
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        } finally {
        }
    }

    useEffect(() => {
        if (access_token) {
            getPackage();
        } else {
            window.location.href = "/home"
        }
    }, [access_token]);

    const sendApi = async () => {
        // exchange_topup
        try {
            const response = await CustomAxios(Config.exchange_topup, {
                data: selectedId,
                package: 0
            }, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                if (response.data.status === true) {
                    Notiflix.Report.success(
                        "Thông báo",
                        response.data.message,
                        "Đóng",
                        function cb() {
                            window.location.reload()
                        }
                    )
                } else {
                    Notiflix.Report.failure(
                        "Thông báo",
                        response.data.message,
                        "Đóng"
                    )
                }
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        } finally {
        }
    };

    const SubmitChange = () => {
        const selectedPackage = isdata.find((item) => item.id === selectedId);
        if (selectedPackage.amount <= 0) {
            Notiflix.Report.failure(
                "Thông báo",
                "Lỗi kết nối internet",
                "Đồng ý"
            )
            return;
        }

        if (selectedId && access_token) {
            try {
                Notiflix.Confirm.show(
                    "Xác nhận",
                    "Xác nhận giao dịch đổi lượng?",
                    "Đồng ý",
                    "Đóng",
                    () => {
                        sendApi();
                    },
                    () => { },
                    {}
                );
            } catch (error) {
                Notiflix.Report.failure(
                    "Thông báo",
                    "Lỗi kết nối internet",
                    "Đồng ý"
                )
            }
        } else {
            return;
        }
    }

    const MyChange = ({
        receive,
        amount,
        id,
        handleClick,
    }) => {
        let nf = new Intl.NumberFormat();
        const myId = Number(123) + Number(id);

        return (
            <div className="col">
                <div className="w-100 fw-semibold cursor-pointer">
                    <div
                        key={myId}
                        onClick={handleClick}
                        className={`recharge-method-item position-relative ${id === selectedId ? "active" : ""
                            }`}
                        style={{ height: "90px" }}
                    >
                        <div>{nf.format(Number(amount))} PCoin</div>
                        <div className="center-text text-danger">
                            <span>Nhận</span>
                        </div>
                        <div className="text-primary">
                            {nf.format(Number(receive))} Lượng
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Quadrants = () => {
        const handleClick = (id) => () => {
            setSelectedId(id);
        };

        if (isdata) {
            return (
                <React.Fragment>
                    {isdata?.map((item) => (
                        <MyChange
                            key={item.id}
                            {...item}
                            handleClick={handleClick(item.id)}
                        />
                    ))}
                </React.Fragment>
            );
        }
        return (
            <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
        )
    };


    const MyChangeChar = ({
        id,
        name,
        classNameS,
    }) => {
        // id === selectedId ? "active" : ""

        return (
            <div className="col" key={id}>
                <div className="w-100 fw-semibold cursor-pointer">
                    <div className={`recharge-method-item ${selectedId ? "active" : ""}`} style={{ height: "95px" }}>
                        <div className="text-dark">
                            <div>
                                <img src={Small426} alt="avatar" style={{ maxHeight: "30px", borderRadius: "0px" }} />
                            </div>
                            <span>{name}</span>
                        </div>
                        <div className="text-danger">{classNameS}</div>
                    </div>
                </div>
            </div>
        );
    };


    const Char = () => {
        // const handleClick = (id) => () => {
        //     setSelectedId(id);
        // };

        if (isdataChar) {
            return (
                <React.Fragment>
                    {isdataChar?.map((item) => (
                        <MyChangeChar
                            key={item.id}
                            classNameS={item.className}
                            {...item}
                        // handleClick={handleClick(item.id)}
                        />
                    ))}
                </React.Fragment>
            );
        }
        return (
            <div className="text-danger text-center fw-semibold mt-3 mb-2">
                Tài khoản chưa có nhân vật nào
            </div>
        )
    }

    const acceptOk = () => {
        setshowDialog(false);
    }


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="text-center fw-semibold fs-5">Đổi PCoin ra Lượng <span className="text-danger">(KM 100%)</span></div>
                    <div className="d-flex justify-content-center">
                        <div className="col-md-8">
                            <div className="row text-center justify-content-center row-cols-2 row-cols-lg-3 g-2 g-lg-2 my-1 mb-2">
                                <Quadrants />
                            </div>
                            <div className="text-center">
                                <div className="fw-semibold fs-6">Chọn nhân vật</div>
                            </div>
                            <div className="row text-center justify-content-center row-cols-2 row-cols-lg-3 g-2 g-lg-2 my-1 mb-2">
                                <Char />
                            </div>
                            <div className="text-center mt-4"><button type="button"
                                className="w-50 rounded-3 btn btn-primary btn-sm" onClick={SubmitChange}>Xác nhận</button>
                                <div className="mt-2"><small className="fw-semibold"><Link to="/user/transactions">Lịch sử giao
                                    dịch</Link></small></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >

            <CustomModal isShow={showDialog} handleHide={null} handleShow={null}>
                <div className="text-center"><Link to='/home'><img className="logo" alt="Logo" src={Logonsovn} style={{ maxWidth: '150px' }} /></Link></div>
                <div className="text-center fw-semibold">
                    <div className="fs-6 mb-2">Thông báo</div>
                    <span className="text-danger">Vui lòng thoát game trước khi đổi lượng, nếu cố chấp mất vật phẩm admin không xử lí vấn đề này</span>
                    <p className="mt-2"><span className="text-danger">Sau khi bạn nhấn vào đồng ý, đồng nghĩa với việc bạn đã kiểm tra kĩ trước khi đổi lượng</span></p>

                    <div className="text-success fw-bold">Xin cảm ơn</div>
                    {/* <div className="mt-2 form-check">
                        <input className="form-check-input" type="checkbox" onChange={acceptOk} />
                        <span className="text-info"> Đồng ý điều khoản</span>
                    </div> */}
                    <div className="mt-2"><button type="button" className="btn-rounded btn btn-primary btn-sm" onClick={() => setshowDialog(false)}>Tôi đã hiểu</button></div>
                </div>
            </CustomModal>
        </>
    )

}
export default Exchange;